import { Col, Container, Row, Button, Card } from "react-bootstrap";
import TrackingComponent from "../components/Home/TrackingComponent";
import * as styles from "../styles/HomePageStyle.module.css";

const data = [
  {
    id: 1,
    url: "https://cdn.pixabay.com/photo/2015/08/02/23/27/aenne-rickmers-872359__340.jpg",
    title: "Ocean Transport",
    body: `We support your business with innovative supply chain
    solutions by taking the complexity out of the shipping journey
    and providing you a smoother logistics experience`,
  },
  {
    id: 2,
    url: "https://cdn.pixabay.com/photo/2020/06/02/00/03/truck-5248809_960_720.jpg",
    title: "Inland Transport",
    body: `We aim to make shipping more straightforward and more efficient.
    We pair your logistics needs with our know-how and digital tools
    to put you in control every step of the way.`,
  },
  {
    id: 3,
    url: "https://cdn.pixabay.com/photo/2014/11/04/08/07/mark-516277__340.jpg",
    title: "Supply Chain Service",
    body: `We support your business with innovative supply chain
    solutions by taking the complexity out of the shipping journey
    and providing you a smoother logistics experience`,
  },
  {
    id: 4,
    url: "https://cdn.pixabay.com/photo/2021/08/22/08/20/industry-6564530__340.jpg",
    title: "Digital Solutions",
    body: `We aim to make shipping more straightforward and more efficient.
    We pair your logistics needs with our know-how and digital tools
    to put you in control every step of the way.`,
  },
];

const HomePage = () => {
  return (
    <div>
      <section className={styles.content}>
        <div className={styles.backdrop}>
          <Container>
            <Row>
              <Col sm={12} md={6} className="my-3">
                <h1 className="text-white">
                  Call us your local integrator of logistics
                </h1>
                <p className="text-white">
                  We support your business with innovative supply chain
                  solutions by taking the complexity out of the shipping journey
                  and providing you a smoother logistics experience
                </p>
                <Button variant="info" style={{color: 'white'}}>Explore More</Button>
              </Col>
              <Col md={6} sm={12} className="my-3 d-none d-sm-block">
                <TrackingComponent />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section className="my-4">
        <Container>
          <div className="d-block d-sm-none">
            <TrackingComponent />
          </div>
          <article className={styles.service_article}>
            <h1>Solutions to simplify your logistics</h1>
            <p>
              We aim to make shipping more straightforward and more efficient.
              We pair your logistics needs with our know-how and digital tools
              to put you in control every step of the way.
            </p>
          </article>
          <Row>
            {data.map((d, i) => {
              return (
                <Col md={3} xs={12} key={i}>
                  <Card className="my-1">
                    <Card.Img
                      variant="top"
                      src={d.url}
                      style={{ height: 200 }}
                    />
                    <Card.Body>
                      <Card.Title>{d.title}</Card.Title>
                      <Card.Text>{d.body}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default HomePage;
