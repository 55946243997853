import { Card, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const TrackingComponent = () => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>Tracking</Card.Title>
        <Form>
          <Form.Group className="my-3" controlId="form-tracking">
            <Form.Label>Waybill Number</Form.Label>
            <Form.Control type="text" placeholder="Eg. EF123456" />
          </Form.Group>
          <Link to="/tracking">
            <Button variant="info" style={{ color: "white" }} type="submit">
              Track
            </Button>
          </Link>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default TrackingComponent;
