import { Container, Form, Row, Button, Col } from "react-bootstrap";
import { BsPrinterFill } from "react-icons/bs";

const TrackingPage = () => {
  return (
    <div className="py-3">
      <section>
        <Container className="my-3">
          <div className="d-flex justify-content-between align-items-center">
            <h1>Tracking</h1>
            <Button variant="info" className="text-white d-sm-block d-none">
              <BsPrinterFill /> Print
            </Button>
          </div>

          <div>Container/transport document number</div>
          <Row>
            <Col md={4} xs={8}>
              <Form.Control className="me-auto" placeholder="Eg. EF123456" />
            </Col>
            <Col md={4} xs={4}>
              <Button variant="info" className="text-white">
                Search
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={4} xs={8}>
              <div>
                Container number is made of 4 letters and 7 digits. Transport
                document number consists of 9 characters.
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default TrackingPage;
