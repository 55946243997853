import { Col, Container, Row } from "react-bootstrap";
import logo from "../../assets/imgs/easyfmm_logo.png";
import { BsFacebook, BsTwitter, BsYoutube, BsLinkedin } from "react-icons/bs";
import { FaWeixin } from "react-icons/fa";

const FooterComponent = () => {
  return (
    <Container className="py-5">
      <Row className="py-5">
        <Col xs={12} sm={6} md={3}>
          <ul>
            <li>About us</li>
            <li>Contact us</li>
            <li>Careers</li>
            <li>Sustainability</li>
            <li>Whistleblower</li>
            <li>Support</li>
            <li>News and advisories</li>
            <li>Used container sales</li>
          </ul>
          <hr className="d-block d-md-none" />
        </Col>
        <Col xs={12} sm={6} md={3}>
          <ul>
            <li>Press</li>
            <li>Terms & conditions</li>
            <li>Privacy policy</li>
            <li>Cookie policy</li>
            <li>Cookie preferences</li>
            <li>Procurement</li>
            <li>Investors</li>
          </ul>
          <hr className="d-block d-md-none" />
        </Col>
        <Col xs={12} sm={6} md={3}>
          <ul>
            <li>Asia</li>
            <li>Americas</li>
            <li>Europe and Mediterranean</li>
          </ul>
          <hr className="d-block d-md-none" />
        </Col>
        <Col xs={12} sm={6} md={3}>
          <ul>
            <li>Shipping and Cargo Services</li>
            <li>Supply Chain Services</li>
            <li>Point to point schedules</li>
            <li>Vessel schedules</li>
            <li>Tariff inquiry (Asia only)</li>
          </ul>
        </Col>
      </Row>
      <img src={logo} alt="logo" width={200} />
      <Row>
        <Col md={6} xs={12}>
          <h5>&copy; Easyfmm - A Logistics Company</h5>
        </Col>
        <Col md={6} xs={12}>
          <ul className="d-block d-md-flex">
            <li className="mx-3">
              <BsLinkedin /> LinkedIn
            </li>
            <li className="mx-3">
              <BsFacebook /> Facebook
            </li>
            <li className="mx-3">
              <BsTwitter /> Twitter
            </li>
            <li className="mx-3">
              <BsYoutube /> YouTube
            </li>
            <li className="mx-3">
              <FaWeixin /> WeChat
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default FooterComponent;
