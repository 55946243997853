import logo from "../../assets/imgs/easyfmm_logo.png";

import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsSearch, BsPersonCircle, BsList } from "react-icons/bs";
const NavBarComponent = () => {
  const style = {
    textDecoration: "none",
    color: "#333",
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img src={logo} alt="logo" width={200} />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link>
              <Link style={style} to="/">
                Home
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link style={style} to="/tracking">
                Tracking
              </Link>
            </Nav.Link>
            <NavDropdown title="Services" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link>
              <Link style={style} to="/about-us">
                About Us
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link style={style} to="/contact-us">
                Contact Us
              </Link>
            </Nav.Link>
          </Nav>
          <Nav className="mr-auto">
            <Nav.Link>
              <Link style={style} to="/search">
                <BsSearch />
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link style={style} to="/account">
                <BsPersonCircle />
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link style={style} to="/menu">
                <BsList />
              </Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBarComponent;
