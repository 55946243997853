import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FooterComponent from "./components/Footer/FooterComponent";
import NavBarComponent from "./components/NavBar/NavBarComponent";
import HomePage from "./pages/HomePage";
import TrackingPage from "./pages/TrackingPage";

function App() {
  return (
    <Router>
      <header>
        <NavBarComponent />
      </header>
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/tracking" element={<TrackingPage />} />
        </Routes>
      </main>
      <footer>
        <FooterComponent />
      </footer>
    </Router>
  );
}

export default App;
